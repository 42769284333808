import logo from "../../assets/green_side_logo.png";
import {LanguageSelector} from "../../components/LanguageSelector";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {selectLoginStatus, signInUser} from "../../redux/slices/identitySlice";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ILoginRequestDto} from "../../dtos/accounts/ILoginRequestDto";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {AppDependencies} from "../../services/appDependencies";

type FormValues = {
    email?: string;
    token?: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

const resolver: Resolver<FormValues> = async (values) => {
    const errors: Partial<Record<keyof FormValues, { type: string; message: string }>> = {};

    if (!values.newPassword) {
        errors.newPassword = {
            type: 'required',
            message: 'New password is required.',
        };
    }

    if (!values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = {
            type: 'required',
            message: 'Password confirmation is required.',
        };
    } else if (values.newPassword !== values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = {
            type: 'mismatch',
            message: 'New password and confirmation do not match.',
        };
    }

    return {
        values: Object.keys(errors).length === 0 ? values : {},
        errors,
    };
};

export const ChangePasswordPage = () => {
    const {register, handleSubmit, formState: {errors, isDirty}, reset} = useForm<FormValues>({resolver});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {email, token} = useParams();

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        if (!email || !token) {
            toast.error("Invalid email or token");
            return;
        }
        
        try {
            setIsSubmitting(true);
            const identityService = AppDependencies.IdentityService;
            const result = await identityService.ChangePasswordWeb({email: email, token: token, newPassword: data.newPassword});
            if (result) {
                navigate("/change-password-success");
                toast.success("Password changed successfully");
                return;
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
        toast.error("The password change has failed, possibly due to an expired token. Please try again from the Login page.");
        // const requestDto: ILoginRequestDto = {...data};
        // const result = await dispatch(signInUser(requestDto));
        // if (result.type === "account/signIn/fulfilled") {
        //     navigate("/home");
        //     return;
        // }
        // // toast.error("Invalid login attempt. Please try again!");
        // reset();
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card border-0 shadow-lg rounded-3 my-5 mx-5">
                            <div className="card-body p-4 p-sm-5">
                                <div className="text-center mb-5">
                                    <img src={logo} alt="logo" width={100} height={100}/>
                                </div>
                                <form className={"needs-validation"} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3">
                                        <input className={`form-control ${errors?.newPassword ? "invalid" : ""}`}
                                               type="password" placeholder={`${t("NewPassword")}...`}
                                               {...register("newPassword")}/>
                                        <label htmlFor="floatingPassword">{t("NewPassword")}</label>
                                        {errors?.newPassword && <span className="text-danger">{errors?.newPassword?.message}</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className={`form-control ${errors?.newPasswordConfirmation ? "invalid" : ""}`}
                                            type="password" placeholder={`${t("NewPasswordConfirmation")}...`}
                                            {...register("newPasswordConfirmation")}/>
                                        <label htmlFor="floatingPassword">{t("NewPasswordConfirmation")}</label>
                                        {errors?.newPasswordConfirmation && <span className="text-danger">{errors?.newPasswordConfirmation?.message}</span>}
                                    </div>
                                    <div className="d-grid pt-3">
                                        <button className="btn btn-primary btn-login text-uppercase fw-bold"
                                                type="submit" disabled={isSubmitting}>
                                            {isSubmitting &&
                                                <span className="spinner-border spinner-border-sm me-2"></span>}
                                            {t("Send")}
                                        </button>
                                    </div>
                                    <div className={"mt-3 text-center"}>
                                        <NavLink to={"/login"}>{t("BackToLogin")}</NavLink>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}