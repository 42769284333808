import React, {useEffect} from "react";
import {Chart, Series, CommonSeriesSettings, Export, Legend, Title, Tooltip, ValueAxis} from "devextreme-react/chart";
import {AppDependencies} from "../../services/appDependencies";
import {IRentChargeResponseChartLineDto} from "../../dtos/analytics/IRentChargeResponseChartLineDto";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel} from "../../redux/slices/mainSlice";
import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import {
    IRentChargeByApartmentResponseChartLineDto
} from "../../dtos/analytics/IRentChargeByApartmentResponseChartLineDto";
import {format} from "date-fns";


interface IProps {
    startDate: Date,
    endDate: Date,
    selectedApartment: IApartmentDto | undefined;
}

export const RentChargeInformation = ({startDate, endDate, selectedApartment}: IProps) => {
    const {t} = useTranslation();
    const [chartData, setChartData] = React.useState<IRentChargeByApartmentResponseChartLineDto[]>([]);
    const [error, setError] = React.useState<string | null>(null);
    const selectedHotel = useAppSelector(getSelectedHotel);

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedApartment) {
                setChartData([]);
                return;
            }
            const response = await AppDependencies.AnalyticsService.GetRentChargeByApartmentInformation(selectedApartment?.id, startDate, endDate);
            if (response) {
                const newChartData = response.chartLines.map((item) => {
                    return {
                        ...item,
                        periodName: format(item.period, "MMM yyyy"),
                        total: item.revenue - item.revenueVat - item.gas - item.water - item.electricity - item.internet - item.propertyManagement - item.commissions - item.incomeTax
                    }
                });
                setChartData(newChartData);
            } else {
                setError("Error fetching data");
            }
        }
        fetchData();
    }, [selectedApartment, startDate, endDate]);

    const customizeTooltip = (arg: any) => {
        return {
            text: `${arg.percentText} amount: ${arg.value.toFixed(2)} GEL`,
        };
    }

    if (!selectedApartment) {
        return <></>
    }

    const getPeriods = () => {
        const periods: Date[] = [];
        chartData.forEach((item) => {
            if (!periods.includes(item.period)) {
                periods.push(item.period);
            }
        });
        return periods;
    }

    const getTotalAmountByPeriod = (period: Date) => {
        let result = 0;
        chartData.forEach((item: IRentChargeByApartmentResponseChartLineDto) => {
            if (item.period === period) {
                result = result + item.revenue - item.revenueVat - item.gas - item.water - item.electricity - item.internet - item.commissions - item.incomeTax - item.propertyManagement;
            }
        });
        return result;
    }

    const getAmountByPeriodAndType = (period: Date, type: string) => {
        let result = 0;
        chartData.forEach((item: any) => {
            if (item.period === period) {
                result = result + item[type];
            }
        });
        return result;
    }

    return (
        <div className={"card card-body shadow mt-3"}>
            <div className="row">
                <div className="col-12">
                    {error && <div className={"alert alert-danger"}>{error}</div>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Chart
                        id="chart"
                        height={400}
                        title="Rent Charge Information"
                        dataSource={chartData}
                    >
                        <CommonSeriesSettings argumentField="periodName" type="fullstackedbar" barWidth={50}/>
                        <Series
                            valueField="total"
                            name="Total"
                        />
                        {/*<Series*/}
                        {/*    valueField="revenueVat"*/}
                        {/*    name="Revenue VAT"*/}
                        {/*/>*/}
                        {/*<Series*/}
                        {/*    valueField="incomeTax"*/}
                        {/*    name="Income Tax"*/}
                        {/*/>*/}
                        {/*<Series*/}
                        {/*    valueField="gas"*/}
                        {/*    name="Gas"*/}
                        {/*/>*/}
                        <Series
                            valueField="commissions"
                            name="Commissions"
                        />
                        {/*<Series*/}
                        {/*    valueField="electricity"*/}
                        {/*    name="Electricity"*/}
                        {/*/>*/}
                        {/*<Series*/}
                        {/*    valueField="water"*/}
                        {/*    name="Water"*/}
                        {/*/>*/}
                        {/*<Series*/}
                        {/*    valueField="internet"*/}
                        {/*    name="Internet"*/}
                        {/*/>*/}
                        {/*<Series*/}
                        {/*    valueField="propertyManagement"*/}
                        {/*    name="Property Management"*/}
                        {/*/>*/}
                        <ValueAxis position="right">
                            <Title text="%"/>
                        </ValueAxis>
                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="top"
                        />
                        <Export enabled={true}/>
                        <Tooltip
                            enabled={true}
                            location="edge"
                            customizeTooltip={customizeTooltip}
                        />
                    </Chart>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>
                    <table className={"table table-striped table-bordered"}>
                        <thead>
                        <tr>
                            <th></th>
                            {getPeriods().map((period, index) => {
                                return <th key={index}>{format(period, "MMM yyyy")}</th>
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Revenue of month</td>
                            {getPeriods().map((period, index) => {
                                return <th key={index}>{getAmountByPeriodAndType(period, "revenue").toFixed(2)}</th>
                            })}
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>Revenue VAT</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "revenueVat").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        <tr>
                            <td>Management commissions</td>
                            {getPeriods().map((period, index) => {
                                return <th key={index}>{getAmountByPeriodAndType(period, "commissions").toFixed(2)}</th>
                            })}
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>Income tax</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "incomeTax").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Internet</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "internet").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Water</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "water").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Electricity</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "electricity").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Gas</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th key={index}>{getAmountByPeriodAndType(period, "gas").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Property managment</td>*/}
                        {/*    {getPeriods().map((period, index) => {*/}
                        {/*        return <th*/}
                        {/*            key={index}>{getAmountByPeriodAndType(period, "propertyManagement").toFixed(2)}</th>*/}
                        {/*    })}*/}
                        {/*</tr>*/}
                        <tr>
                            <td>Payable to owner</td>
                            {getPeriods().map((period, index) => {
                                return <th key={index}>{getTotalAmountByPeriod(period).toFixed(2)}</th>
                            })}
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
