import {useEffect, useState} from "react";
import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import {AppDependencies} from "../../services/appDependencies";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel, setActivePageTitle, setSelectedApartments} from "../../redux/slices/mainSlice";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ApartmentsPage = () => {
    const {t} = useTranslation();
    const [apartments, setApartments] = useState<IApartmentDto[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const selectedHotel = useAppSelector(getSelectedHotel);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            console.log(selectedHotel);
            setApartments(await AppDependencies.ApartmentService.GetApartments(selectedHotel?.id));
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        dispatch(setActivePageTitle({title: "Apartments"}))
        fetchData();
    }, [selectedHotel]);

    const handleOpenCalendar = (apartment: IApartmentDto) => {
        dispatch(setSelectedApartments({apartments: [apartment]}));
        navigate("/calendar");
    }

    return (
        <>
            {/*<Breadcrumb>*/}
            {/*    <BreadcrumbItem>{t("Home")}</BreadcrumbItem>*/}
            {/*    <BreadcrumbItem active>{t("MyApartments")}</BreadcrumbItem>*/}
            {/*</Breadcrumb>*/}
            <div className={""}>
                {error && <div className="alert alert-danger">{error}</div>}
                {isLoading && <div className="alert alert-info">Loading...</div>}
                <div className="row">
                    {apartments && apartments.map((apartment, index) => (
                        <div className="col-sm-12 col-md-3 my-3" key={index}>
                            <div className="card shadow-lg" style={{borderRadius: 3}}>
                                <div className="card-header fw-bold">{apartment.name}</div>
                                <div className="card-body">
                                    <h6 className="card-subtitle mb-2 text-muted">{t("Hotel")}: {apartment.hotelName}</h6>
                                    <h6 className="card-subtitle mb-2 text-muted">{t("CadastralCode")}: {apartment.cadastralCode}</h6>
                                    <h6 className="card-subtitle mb-2 text-muted">{t("Squareness")}: {apartment.squareness}</h6>
                                    <h6 className="card-subtitle mb-2 text-muted">{t("ElectricityId")}: {apartment.electricityId}</h6>
                                    <h6 className="card-subtitle mb-2 text-muted">{t("GasId")}: {apartment.gasId}</h6>
                                    <h6 className="card-subtitle mb-2 text-muted">{t("WaterId")}: {apartment.waterId}</h6>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-outline-secondary"
                                            onClick={() => handleOpenCalendar(apartment)}>
                                        {t("Calendar")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ApartmentsPage;