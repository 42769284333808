import Select, {components} from "react-select";
import ka from "../assets/ge.svg"
import en from "../assets/us.svg"
import ru from "../assets/ru.svg"
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

interface IProps {
    showTitle: boolean;
    shortTitle?: boolean;
}

export const LanguageSelector = ({showTitle, shortTitle}: IProps) => {
    const languages = [
        {value: "en", label: "English", image: en},
        {value: "ka", label: "ქართული", image: ka},
        {value: "ru", label: "Русский", image: ru},
    ]

    //@ts-ignore
    const Option = ({children, ...props}) => (
        //@ts-ignore
        <components.Option {...props}>
            <img src={props.data.image} alt={props.data.label} className={"me-2"} width={16}/>
            {showTitle ? shortTitle ? props.data.value.toUpperCase() : props.data.label : ""}
        </components.Option>
    );
    //@ts-ignore
    const SingleValue = ({children, ...props}) => (
        //@ts-ignore
        <components.SingleValue {...props}>
            <img src={props.data.image} alt={props.data.label} className={"me-2"} width={16}/>
            {showTitle ? shortTitle ? props.data.value.toUpperCase() : props.data.label : ""}
        </components.SingleValue>
    );

    const [currentLanguage, setCurrentLanguage] = useState<any>();

    const {i18n} = useTranslation();

    useEffect(() => {
        setCurrentLanguage(languages.find(l => l.value === i18n.language));
    }, [i18n.language]);

    const handleChangeLanguage = async (newLanguage: any) => {
        await i18n.changeLanguage(newLanguage.value);
    }

    return (
        <>
            <Select options={languages}
                    value={currentLanguage}
                    onChange={handleChangeLanguage}
                    closeMenuOnSelect={true}
                    components={{Option, SingleValue}}
                    styles={{
                        control: (styles, state) => ({
                            ...styles,
                            borderRadius: 6,
                            borderWidth: 1,
                            boxShadow: 'none',
                            fontSize: '12px',
                            borderColor: "#083A2D",
                            '&:hover': {
                                borderColor: "#083A2D88",
                            },
                        }),
                        option: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isSelected || state.isFocused ? "#083A2D" : "#fff",
                            '&:hover': {
                                backgroundColor: "#083A2DCC",
                                color: "#fff",
                            },
                            color: state.isSelected || state.isFocused ? "#fff" : "#083A2D",
                            cursor: "pointer",
                            borderColor: "#083A2D",
                        }),
                    }}
            />
        </>
    )
}