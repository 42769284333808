import {useEffect, useState} from "react";
import {IHotelDto} from "../dtos/hotels/IHotelDto";
import {AppDependencies} from "../services/appDependencies";
import Select, {ActionMeta, SingleValue} from "react-select";
import {useDispatch} from "react-redux";
import {setSelectedHotel} from "../redux/slices/mainSlice";
import {useAppDispatch} from "../hooks/reduxHooks";

interface ISelectOption {
    label: string;
    value: string;
}

export const HotelSelector = () => {
    const [hotels, setHotels] = useState<ISelectOption[] | undefined>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const hotels = await AppDependencies.HotelService.GetHotels();
            if (hotels) {
                const newHotels = hotels.map(hotel => {
                    return {label: hotel.name, value: hotel.id}
                });
                setHotels(newHotels);
            } else {
                setHotels(undefined)
            }
        }
        fetchData();
    }, []);

    const handleChangeHotel = (newValue: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
        if (newValue) {
            dispatch(setSelectedHotel({hotel: {id: newValue.value, name: newValue.label}}));
        } else {
            dispatch(setSelectedHotel({hotel: undefined}));
        }
    }

    return (
        <>
            <Select options={hotels}
                    onChange={handleChangeHotel}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    styles={{
                        control: (styles, state) => ({
                            ...styles,
                            width: 200,
                            borderRadius: 6,
                            borderWidth: 1,
                            boxShadow: 'none',
                            fontSize: '12px',
                            borderColor: "#083A2D",
                            '&:hover': {
                                borderColor: "#083A2D88",
                            },
                        }),
                        option: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isSelected || state.isFocused ? "#083A2D" : "#fff",
                            '&:hover': {
                                backgroundColor: "#083A2DCC",
                                color: "#fff",
                            },
                            color: state.isSelected || state.isFocused ? "#fff" : "#083A2D",
                            cursor: "pointer",
                            borderColor: "#083A2D",
                        }),
                    }}

            />
        </>
    )
}