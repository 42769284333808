import "./MainLayout.css";
import 'devextreme/dist/css/dx.light.css';
import MessengerChat from "../components/FacebookChat";
import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from "../assets/green_side_logo.png";
import {LanguageSelector} from "../components/LanguageSelector";
import {HotelSelector} from "../components/HotelSelector";
import {doLogout} from "../redux/slices/identitySlice";
import {useAppDispatch, useAppSelector} from "../hooks/reduxHooks";
import {getActivePageTitle} from "../redux/slices/mainSlice";

interface IProps {
    children: any;
}

export const MainLayout = ({children}: IProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = React.useState(false);
    const activePageTitle = useAppSelector(getActivePageTitle);
    const handleLogout = (e: any) => {
        e.preventDefault();
        dispatch(doLogout())
    }
    const handleToggleNavbar = () => {
        setCollapsed((prev) => !prev);
    };

    return (
        <>
            <div className={`d-flex ${collapsed ? "toggled" : ""}`} id={"wrapper"}>
                <div id="sidebar-wrapper">
                    <div
                        className={"sidebar-heading text-center py-4 secondary-text fs-4 fw-bold text-uppercase d-flex align-items-center justify-content-start"}>
                        {/*<i className={"fas fa-user-secret me-2"}></i> */}
                        <img src={logo} alt="Green Side" height="40px" className={"me-2"}/>
                        Green Side
                    </div>

                    <div className={"list-group list-group-flush my-3"}>
                        <div className="nav-item px-3">
                            <NavLink className="nav-link" to="/" style={{padding: "0.7rem 0.5rem"}}>
                                <span className="fa-solid fa-user me-3"></span> {t("MyProfile")}
                            </NavLink>
                        </div>
                        <div className="nav-item px-3">
                            <NavLink className="nav-link" to={"/apartments"} style={{padding: "0.7rem 0.5rem"}}>
                                <i className="fa-solid fa-building me-3"></i> {t("MyApartments")}
                            </NavLink>
                        </div>
                        <div className="nav-item px-3">
                            <NavLink className="nav-link" to={"/calendar"} style={{padding: "0.7rem 0.5rem"}}>
                                <i className="fa-solid fa-calendar-days me-3"></i> {t("Calendar")}
                            </NavLink>
                        </div>
                        <div className="nav-item px-3">
                            <NavLink className="nav-link" to={"/analytics"} style={{padding: "0.7rem 0.5rem"}}>
                                <i className="fa-solid fa-chart-line me-3"></i> {t("Analytics")}
                            </NavLink>
                        </div>
                        <div className="nav-item px-3">
                            <NavLink className="nav-link" to={"/my-finance"} style={{padding: "0.7rem 0.5rem"}}>
                                <i className="fa-solid fa-chart-line me-3"></i> {t("MyFinances")}
                            </NavLink>
                        </div>
                        <hr style={{color: "white"}}/>
                        <div className="nav-item px-3">
                            <a href={"#"} className="nav-link" onClick={handleLogout}
                               style={{padding: "0.7rem 0.5rem"}}>
                                <i className="fa-solid fa-right-from-bracket me-3"></i> {t("Logout")}
                            </a>
                        </div>
                    </div>
                </div>
                <div id={"page-content-wrapper"}>
                    <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
                        <div className="d-flex align-items-center">
                            <i className="fas fa-align-left primary-text fs-4 me-3" id="menu-toggle"
                               onClick={handleToggleNavbar}></i>
                            <h2 className="fs-2 m-0 primary-text">{t(activePageTitle)}</h2>
                        </div>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                onClick={handleToggleNavbar}
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className={"d-flex ms-auto"}>
                                <div className={"flex-grow-1"}></div>
                                <HotelSelector/>
                                <div className={"mx-2"}/>
                                <LanguageSelector showTitle={true} shortTitle={true}/>
                            </div>
                            {/*<ul className="navbar-nav ms-auto mb-2 mb-lg-0">*/}
                            {/*    <li className="nav-item dropdown">*/}
                            {/*        <a className="nav-link dropdown-toggle second-text fw-bold" href="#"*/}
                            {/*           id="navbarDropdown"*/}
                            {/*           role="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                            {/*            <i className="fas fa-user me-2"></i>John Doe*/}
                            {/*        </a>*/}
                            {/*        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">*/}
                            {/*            <li><a className="dropdown-item" href="#">Profile</a></li>*/}
                            {/*            <li><a className="dropdown-item" href="#">Settings</a></li>*/}
                            {/*            <li><a className="dropdown-item" href="#">Logout</a></li>*/}
                            {/*        </ul>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </nav>
                    <div className={"mobile-only"}>
                        <div className={"d-flex justify-content-between mb-2 px-4"}>
                            <HotelSelector/>
                            <div className={"mx-2"}/>
                            <LanguageSelector showTitle={false}/>
                        </div>
                    </div>
                    <div className="container-fluid px-4">
                        {children}
                    </div>
                </div>
            </div>

            {/*<div className={"page"}>*/}
            {/*    <div className={"sidebar"}>*/}
            {/*        <NavMenu/>*/}
            {/*    </div>*/}
            {/*    <main>*/}
            {/*        <div className={"top-row px-4"}>*/}
            {/*            <div className={"d-flex"}>*/}
            {/*                <div className={"flex-grow-1"}></div>*/}
            {/*                <HotelSelector/>*/}
            {/*                <div className={"mx-2"}/>*/}
            {/*                <LanguageSelector showTitle={false}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <article className={"content mx-4"}>*/}
            {/*            {children}*/}
            {/*        </article>*/}
            {/*    </main>*/}
            {/*</div>*/}
            <MessengerChat
                pageId="108689314229473"
                appId="YOUR_FACEBOOK_APP_ID"
                themeColor="#0084ff"
                loggedInGreeting="Hi! How can we help you?"
                loggedOutGreeting="Hi! How can we help you?"
                greetingDialogDisplay="show"
                greetingDialogDelay={10}
            />
        </>
    )
}