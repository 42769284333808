import React, {useEffect, useRef, useState} from "react";
import {DataGrid} from "devextreme-react";
import {
    Column,
    FilterPanel, FilterRow,
    Grouping,
    GroupItem, GroupPanel, HeaderFilter, SearchPanel,
    Selection,
    SortByGroupSummaryInfo, Sorting,
    Summary
} from "devextreme-react/data-grid";
import {IFolioTransactionDto} from "../../dtos/folios/IFolioTransactionDto";
import {AppDependencies} from "../../services/appDependencies";
import {useNavigate} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import CustomStore from "devextreme/data/custom_store";
import {toast} from "react-hot-toast";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel, setActivePageTitle} from "../../redux/slices/mainSlice";
import {LuPrinter} from "react-icons/lu";


export const MyFinancePage = () => {
    const {t} = useTranslation();
    // const [folios, setFolios] = useState<IFolioTransactionDto[]>([])
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let dataGrid = useRef<DataGrid>(null);
    const selectedHotel = useAppSelector(getSelectedHotel);

    const customDataStore = new CustomStore({
        key: "id",
        load: async (options) => {
            // const items = generateFolioTransactionData(100);
            const folioService = AppDependencies.FolioService;
            const items = await folioService.getFolios();
            // console.log(items);
            return items;
        },
    });

    useEffect(() => {
        dispatch(setActivePageTitle({title: "MyFinances"}))
    }, [])

    const handlePrintInvoice = () => {
        if (!dataGrid?.current) return;

        const rows = dataGrid.current.instance.getSelectedRowsData();
        if (rows.length === 0) {
            toast.error("Please select at least one row!");
            return;
        }

        const folioId = rows[0].folioId;
        navigate(`/folio-invoice/${folioId}`)
    }

    return (
        <>
            {/*<Breadcrumb>*/}
            {/*    <BreadcrumbItem>{t("Home")}</BreadcrumbItem>*/}
            {/*    <BreadcrumbItem active>{t("MyFinance")}</BreadcrumbItem>*/}
            {/*</Breadcrumb>*/}
            <div className={"card card-body shadow"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-primary"} onClick={handlePrintInvoice}>
                            <div className={"d-flex gap-2 align-items-center"}>
                                <LuPrinter/>
                                {t("PrintFolio")}
                            </div>
                        </button>
                    </div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col-12 overflow-x"}>
                        <DataGrid ref={dataGrid}
                                  id="gridContainer"
                                  scrolling={{showScrollbar: "always"}}
                                  dataSource={customDataStore}
                                  showBorders={true}>
                            <Selection mode="single"/>
                            <Grouping autoExpandAll={true}/>
                            <FilterPanel visible={true}/>
                            <FilterRow visible={true}/>
                            <SearchPanel visible={true}/>
                            <Sorting mode={"multiple"}/>
                            <HeaderFilter visible={true}/>
                            <GroupPanel visible={true}/>

                            <Column width={100} dataField={"folioDocumentNumber"} caption={t("Number")} groupIndex={0}/>
                            <Column width={100} dataField={"documentType"} caption={t("Document")}/>
                            <Column width={100} dataField={"currencyName"} caption={t("Currency")}/>
                            <Column width={200} dataField={"hotelName"} caption={t("Hotel")}/>
                            <Column width={100} dataField={"companyName"} caption={t("Company")}/>
                            <Column width={100} dataField={"roomName"} caption={t("Room")}/>
                            <Column width={100} dataField={"period"} caption={t("Period")} sortIndex={0}
                                    format={"dd/MM/yyyy"}/>
                            <Column minWidth={300} width={"*"} dataField={"serviceDescription"} caption={t("Service")}/>
                            <Column width={200} dataField={"debtAdvance"} caption={t("DebtAdvance")} format={{
                                type: "fixedPoint",
                                precision: 2
                            }}/>
                            <Column width={200} dataField={"currencyRate"} caption={t("Rate")} format={{
                                type: "fixedPoint",
                                precision: 4
                            }}/>
                            <Column width={200} dataField={"debtAdvanceGEL"} caption={`${t("DebtAdvance")} GEL`}
                                    format={{
                                        type: "fixedPoint",
                                        precision: 2
                                    }}/>

                            <Summary>
                                <GroupItem
                                    column="folioDocumentNumber"
                                    summaryType="count"
                                    displayFormat="{0} transactions"/>
                                {/*<GroupItem*/}
                                {/*    column="SaleAmount"*/}
                                {/*    summaryType="max"*/}
                                {/*    valueFormat="currency"*/}
                                {/*    showInGroupFooter={false}*/}
                                {/*    alignByColumn={true} />*/}
                                {/*<GroupItem*/}
                                {/*    column="TotalAmount"*/}
                                {/*    summaryType="max"*/}
                                {/*    valueFormat="currency"*/}
                                {/*    showInGroupFooter={false}*/}
                                {/*    alignByColumn={true} />*/}
                                <GroupItem
                                    column="debtAdvance"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    displayFormat="Total: {0}"
                                    alignByColumn={true}
                                    showInGroupFooter={false}/>
                            </Summary>
                            {/*<SortByGroupSummaryInfo summaryItem="count" />*/}
                        </DataGrid>
                    </div>
                </div>
            </div>
        </>
    )
}